
body {
  padding-bottom: 2em;
  font-family: "Brown", "PT Sans", Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  color: #363636;
  background-color: #efedee;
}

header {
  background-color: #fff;
  border-top: 5px solid #e3671f;
  padding: 2em 1em 0.5em 1em;
}

svg {
  width: 100%;
}

header h1 {
  opacity: 0.9;
  font-size: 1.5em;
  margin-bottom: 0;
}

a,
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  
  color: #e3671f;
  text-decoration: underline;
}
a:hover, 
button:hover {
  cursor: pointer;
  background-color: #e3671f;
  color: white;
}

.cardWrapper {
  position: relative;
  height: 18.6206em;
  width: 12em;
  border: 1px solid #ccc; 
  box-shadow: -5px 5px 15px 0px rgba(0,0,0,0.15);
  border-radius: 10px;
}


.cardList-listItem {
  position: relative;
  height: 18.6206em;
  width: 12em;
  margin: 10px;
}

.card .fnlogo {
  display: block;
  width: 150px;
  margin: 1.2em auto -0.5em auto;
}

.card {
  height: 18.6206em;
  width: 12em;
  border-radius: 10px;
  background: rgb(248,246,245);
  background: radial-gradient(circle, rgba(255,253,250,1) 0%, rgba(242,240,238,1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(54, 54, 54);
}

.card .card-insignia {
  font-size: 1.5em; 
}

.card .card-insignia .corner   {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
}

.card-joker .card-insignia .corner {
  width: 60px;
  margin-left: -10px;
  margin-right: -10px;
}

.card .card-insignia .icon {
  display: block;
  width: 60px;
  padding: 10px 0;
}

.card .card-insignia--bottom .icon {
  display: block;
  transform: rotate(180deg);
} 

.card .card-insignia--bottom .corner {
  bottom: 0;
  right: 0;
  left: auto;
  top: auto;
  transform: rotate(180deg);
}

.card .value  {
  font-family: 'Georgia', serif;
}

.card-diamonds .card-insignia,
.card-hearts .card-insignia  {
  color: #e3671f;
}

.card-clubs .card-insignia,
.card-spades .card-insignia {
  color: #363636;
}

.card-diamonds .card-insignia path,
.card-hearts .card-insignia path  {
  fill: #e3671f !important;
}

.card-clubs .card-insignia path,
.card-spades .card-insignia path {
  fill: #363636 !important;
}

.card .text {
  display: block;
  text-align: center;
  padding: 0.5em;
  font-size: 0.9em;
}

.card-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 17.1206em;
  width: 10.5em;
}

.cardList {
  font-size: 1.5em;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  perspective: 1000px;
  justify-content: center;
  margin-bottom: 2em;
}
.cardList .cardWrapper {
  transform-style: preserve-3d;
}


.card-front div {
  font-size: 2em;
}

.cardWrapper .card-back {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all ease;
  backface-visibility: hidden;
}

.cardWrapper .card-back * {
  backface-visibility: hidden;
}



.cardWrapper .card.card-front {
  background-color: #e3671f;
  background: radial-gradient(circle, #e3671f 0%, #ce5c19 100%);
  color: white;
  font-family: 'PT Sans';
  text-align: left !important;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  transition: all ease;
}

.cardWrapper.flipped .card {
  transform: rotateY( 180deg );
}

.cardWrapper.flipped .card-front {
  transform: rotateY( 0 );
}


.card-front .knekk {
  font-weight: bold;
  display: table;
  text-align: left !important;
  color: #e3671f;
  background-color: white;
  line-height: 0.8;
  margin: 0.4em 0;
  padding: 0;
}

#allCards {}

#allCards.hidden {
 max-height: 1px;
 overflow: hidden;
 opacity: 0;
}

#menu {
  position: absolute;
  top: 2em;
  right: 1em;
}

@media screen and (max-width: 992px) {
  #menu {
    padding-top: 1.5em;
    position: relative;
    top: 0;
    right: 0;
  }
}

.editable {
  min-width: 60%;
  border-bottom: 2px solid #363636;
}

main {
  padding-top: 1px;
  padding-bottom: 1.5em;
  background-color: white;
}

aside {
  border-top: 2px solid #ddd;
  background-color: #efedee;
  padding-top: 2.5em;
}

